/* eslint-disable no-useless-concat */
// eslint-disable-next-line import/prefer-default-export
export const clothingHero = {
  title: "Clothing store POS",
  subtext:
    "Sell in style—both in-store and online—with the all-in-one retail point-of-sale built for clothing and apparel businesses like yours. ",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/retail/clothing-and-apparel/demo",
  },
};
export const clothingSubHero = {
  title: "Boost sales with a flexible retail point-of-sale system",
  list: [
    "Save time and streamline sales with a single catalog for selling in your store and online",
    "Increase sales with a custom website and ecommerce storefront",
    "Engage your customers with built-in marketing, review management, and reporting tools",
    "Offer easier ways to collect payments for a better shopping experience—no matter where your customers are ",
  ],
  subtext:
    "Your customers’ expectations are higher than ever. They want better and more convenient ways to shop and pay. Make it easy for them to support your business by selling in-store and online—with one cloud-based catalog for your products and services.",
  ctaPrime: {
    ctaTitle: "Learn more",
    ctaTarget: "/retail/clothing-and-apparel/demo",
  },
};

export const clothingWhiteGlove = {
  mainTitle: "A partner you can trust",
  title: "Local service and \n around-the-clock support",
  content:
    "You deserve the same level of service and value that you provide to your customers. Our team of experts is ready to set up a personalized solution that will help you increase sales, from building your custom POS catalog to creating a stunning online store, and teaching your team to get the most from the built-in marketing tools. And we’ll continue to be there with local service and around-the-clock support.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/retail/clothing-and-apparel/demo",
  },
};

export const clothingLargeFeatureA = {
  featureBlocks: [
    {
      blockTitle: "Sleek tech tools that help you do more with less",
      blockSubTitle:
        "Your customers are your top priority, so why waste time with mismatched product catalogs and multiple software platforms? Our cloud-based tools work well together, with a single login, so you can focus on selling more and creating a seamless customer experience that will help you build your brand and grow your business.",
      blockImg: "clothing-a.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/retail/clothing-and-apparel/demo",
      },
    },
  ],
};

export const clothingTrusted = [
  {
    title: "72%",
    subtext:
      "of Americans prefer to buy from an independent store over a national retailer if equally convenient and reliable",
  },
  {
    title: "78%",
    subtext:
      "of Americans prefer to purchase from a retailer offering both in-person and online options",
  },
  {
    title: "68%",
    subtext:
      "of Americans prefer to buy gifts from an independent store instead of a national retailer if delivered to their doorstep",
  },
  {
    title: "73%",
    subtext:
      "of Americans would buy more last-minute gifts online from independent stores if they had guaranteed delivery dates",
  },
];

export const clothingMultiSection = {
  sectionTitle: "Trendsetting tools to elevate your business",
  featureBlocks: [
    {
      blockTitle: "In-store. Online. All in one",
      blockSubTitle:
        "Say goodbye to clashing systems for your in-store POS and online store. With SpotOn Retail, you get a cloud-based omnichannel catalog to manage and update your products from one place. Plus, you can expand your business by selling styling services like tailoring, wardrobe renovation, and personal styling—right from your website.",
      blockList: [
        "One stock for in-store and online to save time and hassles",
        "Intuitive catalog navigation for speedy in-store checkout",
        "Stylish online store—for both your products and services",
        "Multiple ways for customers to pay, including Apple Pay and Google Pay",
        "Customer data capture for marketing",
        "Online dashboard for transaction data & analytics",
      ],
      blockImg: "clothing-b.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/retail/clothing-and-apparel/demo",
      },
    },
    {
      blockTitle: "Compete with national retailers by selling online",
      blockSubTitle:
        "Sell better with a custom online store that’s easy for customers and gives you all the tools you need to make shipping and local delivery easy. Our experts can even build a new custom website if you need one. Just choose the products you want to sell online from your cloud-based catalog and publish them to your digital storefront.",
      blockList: [
        "Offer same-day delivery with our affordable, flat-fee DoorDash integration",
        "Provide curbside pickup and local 2-day delivery options",
        "Ship nationwide with the lowest shipping rates available",
        "Optional loyalty rewards to help boost repeat business",
      ],
      blockImg: "clothing-d.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/retail/clothing-and-apparel/demo",
      },
    },
    {
      blockTitle: "Market like a pro",
      blockSubTitle:
        "Boost sales with digital marketing campaigns to bring shoppers to your store or drive traffic to your website with our integrated marketing digital tools. Send digital coupons, marketing emails, and create Facebook campaigns to keep in touch with your customers and track campaign performance with real-time analytics—all from one platform.",
      blockList: [
        "Grow your customer base and keep them coming back",
        "Connect with clients via Facebook, email, and push notifications",
        "Manage campaigns online or with our free mobile app",
      ],
      blockImg: "clothing-c.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/retail/clothing-and-apparel/demo",
      },
    },
    // {
    //   blockTitle: "Get a stylish website that matches your brand",
    //   blockSubTitle:
    //     "Attract more customers with a beautifully designed website at a do-it-yourself price. We'll help you optimize it so you show up higher in online searches and attract more customers. Plus, we’ll build your online store and customize it with your products, so you’re ready to sell with style.",
    //   blockImg: "clothing-d.png",
    //   blockList: [
    //     "Custom website design",
    //     "Ecommerce store—for both products and services",
    //     "Web hosting & custom website name",
    //     "Lightning-fast—as soon as 48 hours",
    //     "Self-service dashboard",
    //     "Lifetime support",
    //   ],
    //   forceReverse: false,
    //   ctaPrime: {
    //     ctaTitle: "Learn more",
    //     ctaTarget: "/retail/clothing-and-apparel/demo",
    //   },
    // },
    {
      blockTitle:
        "Take care of your loyal customers, and they'll take care of you",
      blockSubTitle:
        "Your customers are your best asset. Show them you value their business, while increasing revenue, with a digital loyalty program. SpotOn Loyalty is fully integrated in the POS and practically runs itself—encouraging repeat visits, higher spends, and helping you grow revenue.",
      blockImg: "clothing-e.png",
      blockList: [
        "Create custom rewards",
        "Enroll customers during checkout",
        "Enable automated email deals to encourage repeat visits",
        "Increase sales with every check-in and redemption",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/retail/clothing-and-apparel/demo",
      },
    },
    {
      blockTitle: "Make a statement with your online reputation",
      blockSubTitle:
        "There's no way around it. Your clients have opinions, and they’ll make sure to express them on review sites like Yelp, Facebook, and Google. Good or bad, it can significantly impact your store. With our review management software, you can proactively oversee your online reputation—from the same online dashboard you use to manage the rest of your tools.",
      blockList: [
        "Monitor and see all your online reviews in one place",
        "Get notified every time you get a new review",
        "Easily shift to review sites to respond",
        "Watch your reputation improves with time",
      ],
      blockImg: "clothing-f.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/retail/clothing-and-apparel/demo",
      },
    },
  ],
};

export const clothingCTA = {
  title: "Book your consultation.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/retail/clothing-and-apparel/demo",
  },
};

export const clothingTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const clothingArticles = {
  title: "Expert articles & advice on growing your business",
  bgText: "Articles.",
};

export const hairCar = {
  title: "Run a different type of retail business",
  subtext: "Clothing & apparel stores are just one of our specialties.",
  punctuation: "?",
  slides: [
    {
      bg: "sporting.png",
      cardTitle: "Sporting Goods",
      cardContent: "",
      icon: "sporting.png",
      linkTo: "/retail/sporting-goods",
    },
    {
      bg: "home-decor.png",
      cardTitle: "Home decor",
      cardContent: "",
      icon: "home-decor.png",
      linkTo: "/retail/home-decor",
    },
  ],
};
