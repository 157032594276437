import React from "react";
import PropTypes, { any } from "prop-types";
import { Row, Col, Typography } from "antd";
import HeroImages from "../ImageQuerys/HeroImages";
import PrimaryCta from "../Buttons/primary-cta";
import SecondaryCta from "../Buttons/secondary-cta";
import HeroStyles from "./HeroStyles";

const { Title } = Typography;
const VideoHero = (props) => {
  const {
    sectionData,
    heroBg,
    imageName,
    customTop,
    clearBg,
    customHeroContent,
    bgVideo,
    videoType,
  } = props;

  return (
    <section
      className="hero-b"
      style={{
        paddingTop: ` ${customTop}`,
        borderRadius: "28px",
      }}
      id="my-bg"
    >
      {!clearBg && <span className="gradient-cover is-radial" />}
      <video id="videoBG" poster={heroBg} autoPlay="autoplay" muted loop="loop">
        <source src={bgVideo} type={videoType} />
      </video>
      <HeroImages imageName={imageName} className="special-image" />
      <Row align="middle">
        <Col lg={24} xs={24} className="hero__content">
          {customHeroContent && customHeroContent}
          {sectionData.title && (
            <Title className="hero-b__title text-center">
              {sectionData.title}
              <span className="blue_dot">.</span>
            </Title>
          )}
          {sectionData.subtext && (
            <p className="hero-b__para text-center">{sectionData.subtext}</p>
          )}
          <Row
            style={{ marginTop: "42px" }}
            className="hero__btns"
            align="middle"
            justify="center"
            wrap={false}
          >
            {sectionData?.PrimaryCta && (
              <PrimaryCta
                ctaTitle={sectionData.PrimaryCta.ctaTitle}
                target={sectionData.PrimaryCta.ctaTarget}
                style={{ marginLeft: 15 }}
              />
            )}

            {sectionData?.SecondaryCta && (
              <SecondaryCta
                ctaTitle={sectionData.SecondaryCta.ctaTitle}
                target={sectionData.SecondaryCta.ctaTarget}
              />
            )}
          </Row>
        </Col>
      </Row>
      <HeroStyles />
    </section>
  );
};

VideoHero.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
  heroBg: PropTypes.any,
  imageName: PropTypes.string,
  customTop: PropTypes.string,
  clearBg: PropTypes.bool,
  customHeroContent: PropTypes.any,
  bgVideo: PropTypes.string,
  videoType: PropTypes.string,
};
VideoHero.defaultProps = {
  sectionData: "",
  heroBg: "",
  imageName: "",
  customTop: "125px",
  clearBg: false,
  customHeroContent: "",
  bgVideo: "",
  videoType: "video/mp4",
};

export default VideoHero;
