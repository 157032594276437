import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
// import BHero from "../../../components/Hero/b-hero";
import {
  clothingHero,
  clothingSubHero,
  clothingWhiteGlove,
  clothingLargeFeatureA,
  clothingTrusted,
  clothingMultiSection,
  clothingCTA,
  clothingTestimonials,
  clothingArticles,
  // clothingFaqs,
  hairCar,
} from "../../../data/subverticals/retail/clothing-data";
import { retailOverviewVideo } from "../../../data/subverticals/retail/retail-overview-data";
import VideoHero from "../../../components/Hero/VideoHero";
// import NoVisualsCustom from "../../../components/NoVisuals/NoVisualsCustom";
// import WhiteGlove from "../../../components/WhiteGlove/WhiteGlove";
// import LargeFeatures from "../../../components/LargeFeatures/LargeFeatures";
// import TrustedNumbers from "../../../components/TrustedNumbers/TrustedNumbers";
// import LargeCta from "../../../components/CTAs/LargeCta";
// import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";
// import BusinessTypes from "../../../components/BusinessTypes/BusinessTypes";
// import Articles from "../../../components/Articles/Articles";
// import VideoSection from "../../../components/VideoSection/VideoSection";
import heroBg from "../../../images/hero-images/hair-salon-white-glove.png";
import video from "../../../images/videos/onmichannel.mp4";

const NoVisualsCustom = loadable(() =>
  import("../../../components/NoVisuals/NoVisualsCustom")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const TrustedNumbers = loadable(() =>
  import("../../../components/TrustedNumbers/TrustedNumbers")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const TestmonialReviews = loadable(() =>
  import("../../../components/TestimonialReviews/TestmonialReviews")
);
const BusinessTypes = loadable(() =>
  import("../../../components/BusinessTypes/BusinessTypes")
);
const Articles = loadable(() =>
  import("../../../components/Articles/Articles")
);
const VideoSection = loadable(() =>
  import("../../../components/VideoSection/VideoSection")
);

const whiteGloveImport = "clothing-white-glove.png";

const Clothings = () => {
  return (
    <Layout>
      <SEO
        title="Clothing Store Inventory (POS) Software for Retailers | SpotOn"
        description="SpotOn has all you need to run your apparel store. Our next-level online catelog allows you to sell online seamlessly."
        image={`https://spoton.com/${heroBg}`}
      />
      <VideoHero
        sectionData={clothingHero}
        heroBg="clothing-hero.png"
        bgVideo={video}
      />
      <NoVisualsCustom sectionData={clothingSubHero} complex />
      <WhiteGlove
        sectionData={clothingWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeFeatures
        sectionData={clothingLargeFeatureA}
        style={{ marginTop: "128px" }}
      />
      <section style={{ marginBottom: 80 }}>
        <TrustedNumbers title="By the numbers" numbersArray={clothingTrusted} />
      </section>
      <LargeFeatures
        sectionData={clothingMultiSection}
        style={{ marginTop: "128px !important" }}
      />
      <VideoSection sectionData={retailOverviewVideo} />
      <LargeCta sectionData={clothingCTA} />
      {/* <SuccessStories sectionData={heroSuccess} slides={hbBTSlides} /> */}
      <TestmonialReviews sectionData={clothingTestimonials} />
      <Articles
        sectionData={clothingArticles}
        blogFilters={{
          limit: 3,
          include: "tags,authors",
          filter: "tag:small-business",
        }}
        cornerStone={false}
        style={{ marginTop: 60 }}
      />
      <BusinessTypes sectionData={hairCar} />
      {/* <Faqs sectionData={clothingFaqs} /> */}
    </Layout>
  );
};

export default Clothings;
